import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Detail.scss';
import common from '../../common'; 
import { FaDownload } from 'react-icons/fa';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Detail(isLogin) {

    const { useAlert, useConfirm, useModal, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pModal, ModalComponent } = useModal();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const targetIdx = queryParams.get('id');

    const [data , setData] = useState([]);
    const [files, setFiles] = useState([]);

    const [memberDepartChk, setMemberDepartChk] = useState(false);

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_detail`, { targetIdx:targetIdx }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                setData(response.data.data);
                setFiles(response.data.data.file);
                if(response.data.data.memberDepart===true){
                    setMemberDepartChk(true);
                }

                const titEl = document.querySelector('.top_only_big_tit');
                if (titEl) {
                    titEl.textContent = response.data.data.documentCategoryName;
                }
                const titEl2 = document.querySelector('.mo_hd_page_tit');
                if (titEl2) {
                    titEl2.textContent = response.data.data.documentCategoryName;
                }

            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    const [memberDepartEditStat , setMemberDepartEditStat] = useState(false);
    const memberDepartEditChk = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_edit_possible`,
                { doc:targetIdx },
                { withCredentials: true }
            );
            if (response.data.result === 't' && response.data.msg===true) {
                setMemberDepartEditStat(true);
            } else {
                setMemberDepartEditStat(false);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    const gogopage = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_edit_possible`,
                { doc:targetIdx },
                { withCredentials: true }
            );
            if (response.data.result === 't' && response.data.msg===true) {
                window.location.href = response.data.data;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        if(isLogin && memberDepartChk){
            memberDepartEditChk();
        }
    }, [memberDepartChk]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleFileDownload = async (file) => {
		if (file.isLocal) {
            pLoadingOn();
			const link = document.createElement('a');
			link.href = file.serverName;
			link.download = file.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
            pLoadingOff();
		} else {
			try {
				pLoadingOn();
				const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_file`, {
                    idx: [file.idx],
                    serverName: file.serverName,
                    name: file.name
                }, {
                    withCredentials: true,
                    responseType: 'blob'  // 응답 타입을 blob으로 설정
                });

                if (response.status === 200) { // HTTP 응답 상태 코드가 200인 경우
                    const blob = response.data;
                    const downloadUrl = window.URL.createObjectURL(blob);

                    // Blob을 사용하여 파일 다운로드
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = file.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Blob URL을 해제
                    window.URL.revokeObjectURL(downloadUrl);
                } else {
                    pAlert('파일 다운로드에 실패했습니다.');
                }

			} catch (error) {
				pAlert(`파일다운에 실패했습니다. 다시 시도해 주세요.`);
			} finally {
				pLoadingOff();
			}
		}
	};

    function fileContent (){
        return (
            <div>
                {files.map((file, index) => (
                    <div key={index}>
                        <button
                            onClick={() => handleFileDownload(file)}
                            className='p_detail_file_txt'
                        >{file.name} <FaDownload /></button>
                    </div>
                ))}
            </div>
        );
    }
    

    return (
        <>
            <LoadingComponent />
            <ModalComponent />
            <div className="components_wrap p_document_detail_wrap">
                <div className='p_detail_wrap'>
                    <div className='p_detail_header'>
                        <h1>{data.title}</h1>
                        <div className='p_detail_meta'>
                            {data.adminName && (
                                <span className='adminName'>{data.adminName}</span>
                            )}
                            <span className='date'>{data.createTime}</span>
                            <span className='views'><img src="/img/views.png" alt="views" />{data.view}</span>
                        </div>
                    </div>
                    {Array.isArray(data.file) && data.file.length > 0 ? (
                        <div className='p_detail_file_box'>
                            <button className='p_detail_file_down_btn' onClick={() => pModal('',fileContent,'auto','auto')}>
                                첨부파일({data.file.length})<i className='download_icon'></i>
                            </button>
                        </div>
                    ) : (
                        <div className='p_detail_file_box'>
                            {/* <span>해당 게시글은 첨부파일이 없습니다.</span> */}
                        </div>
                    )}
                        {/* 기타 내용 */}
                    <div className='p_detail_content' dangerouslySetInnerHTML={{ __html: data.contents }} />
                    
                    {memberDepartEditStat && (
                        <div className='md_foot_right_btn_box'>
                            <button className={`p_btn p_btn_normal md_foot_btn`} onClick={gogopage}>수정</button>
                        </div>
                    )}
                </div>

            </div>
            
        </>
    );
}

export default Detail;
